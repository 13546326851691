<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="修改用户"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <!--        <el-form-item label-width="80px" label="用户名" prop="username">-->
        <!--          <el-input v-model="formData.username" placeholder="请输入用户名" :maxlength="16" clearable-->
        <!--                    prefix-icon='el-icon-user' :style="{width: '100%'}"></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label-width="80px" label="密码" prop="password">-->
        <!--          <el-input v-model="formData.password" placeholder="请输入密码" clearable-->
        <!--                    prefix-icon='el-icon-lock'-->
        <!--                    show-password :style="{width: '100%'}"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label-width="80px" label="真实姓名" prop="realName">
          <el-input
            v-model="formData.realName"
            placeholder="请输入真实姓名"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="备注" prop="nickName">
          <el-input
            v-model="formData.nickName"
            placeholder="请输入备注"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="80px" label="手机号" prop="phone">
          <el-input
            v-model="formData.phone"
            placeholder="请输入手机号"
            :maxlength="16"
            clearable
            prefix-icon="el-icon-user"
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="80px"
          label="账号状态"
          prop="enable"
          required
        >
          <el-tooltip :content="'账号状态: ' + formData.enable" placement="top">
            <el-switch v-model="formData.enable"> </el-switch>
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { editUser } from "@/api/user";

export default {
  name: "UserEditDialog",
  components: {},
  props: {
    dialogVisible: Boolean,
    row: Object,
  },
  data() {
    return {
      visible: false,
      formData: {
        username: "",
        password: undefined,
        realName: "",
        nickName: "",
        phone: "",
        enable: true,
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        nickName: [
          {
            required: true,
            message: "请输入备注",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
    row: {
      handler(value) {
        this.formData = value;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
    this.formData = JSON.parse(JSON.stringify(this.row));
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.formData.enable === true) {
          this.formData.enable = 1;
        } else {
          this.formData.enable = 0;
        }
        const params = {
          username: this.formData.username,
        };
        const requestBody = this.formData;
        editUser(params, requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
  },
};
</script>
<style></style>
