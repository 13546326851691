<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="分配角色"
    >
      <el-row :gutter="10">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="mini"
          label-width="px"
          label-position="left"
        >
          <el-col :span="24">
            <el-form-item label-width="80px" label="角色选择" prop="roles">
              <el-select
                v-model="formData.roles"
                placeholder="请选择角色"
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(item, index) in rolesOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getRoleNames } from "@/api/role";
import { getRoleByUser, setRoleByUser } from "@/api/user";

export default {
  name: "SetRoleDialog",
  components: {},
  props: {
    dialogVisible: Boolean,
    row: Object,
  },
  data() {
    return {
      visible: false,
      username: undefined,
      formData: {
        roles: undefined,
      },
      rules: {
        roles: [
          {
            required: true,
            message: "请选择下拉选择角色选择",
            trigger: "change",
          },
        ],
      },
      rolesOptions: [
        {
          label: "超级管理员",
          value: 1,
        },
        {
          label: "测试角色",
          value: 2,
        },
      ],
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
    row: {
      handler(value) {
        this.username = value.username;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
    this.username = this.row.username;
  },
  methods: {
    onOpen() {
      this.getOptions();
    },
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        const params = {
          username: this.username,
          roleName: this.formData.roles,
        };
        console.log("绑定的角色的请求参数:", params);
        setRoleByUser(params).then((result) => {
          console.log(result);
        });
        this.close();
      });
    },
    // 获取角色名列表
    getOptions() {
      getRoleNames().then((result) => {
        this.rolesOptions = result.data;
        const params = {
          username: this.username,
        };
        getRoleByUser(params).then((result) => {
          this.formData.roles = result.data;
          console.log(result.data);
        });
      });
    },
  },
};
</script>
<style></style>
