<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 系统管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input
            clearable
            size="mini"
            v-model="usernameInput"
            placeholder="用户名"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddUser"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加用户
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="realName" label="真实姓名"> </el-table-column>
        <el-table-column prop="nickName" label="备注"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="enable" label="账号状态">
          <template #default="scope">
            <el-switch
              :disabled="scope.row.username === 'superAdmin'"
              v-model="scope.row.enable"
              @click="handleSwitch(scope.$index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="lastLoginTime" label="最后登录">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <div>
              <el-button
                :disabled="scope.row.username === 'superAdmin'"
                size="mini"
                type="text"
                @click="handleAddRole(scope.$index, scope.row)"
              >
                分配角色
              </el-button>
              <el-button
                :disabled="scope.row.username === 'superAdmin'"
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)"
              >
                编辑
              </el-button>
            </div>
            <div>
              <el-button
                :disabled="scope.row.username === 'superAdmin'"
                size="mini"
                type="text"
                @click="handleBinding(scope.$index, scope.row)"
              >
                绑定监控
              </el-button>
              <el-button
                :disabled="scope.row.username === 'superAdmin'"
                size="mini"
                type="text"
                @click="handleDelete(scope.$index, scope.row)"
              >
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <user-add-dialog
      :dialog-visible="dialogVisibleUserAdd"
      @updateVisible="closeAddUser"
    ></user-add-dialog>
    <user-edit-dialog
      :dialog-visible="dialogVisibleUserEdit"
      :row="editRow"
      @updateVisible="closeEditUser"
    ></user-edit-dialog>
    <set-role-dialog
      :dialog-visible="dialogVisibleRoleSet"
      :row="editRow"
      @updateVisible="closeSetRole"
    ></set-role-dialog>
  </div>
</template>
<script>
import UserAddDialog from "@/views/sys/user/dialog/UserAdd";
import SetRoleDialog from "@/views/sys/user/dialog/SetRole";
import UserEditDialog from "@/views/sys/user/dialog/UserEdit";
import { deleteUser, getTable, switchUserStatus } from "@/api/user";
import router from "@/router";

export default {
  name: "User",
  components: { UserEditDialog, SetRoleDialog, UserAddDialog },
  props: [],
  data() {
    return {
      usernameInput: "",
      dialogVisibleUserAdd: false,
      dialogVisibleUserEdit: false,
      dialogVisibleRoleSet: false,
      editRow: {},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      user: {},
      rules: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleAddRole(index, row) {
      console.log(index, row);
      this.editRow = row;
      this.dialogVisibleRoleSet = true;
    },
    handleEdit(index, row) {
      this.editRow = JSON.parse(JSON.stringify(row));
      this.dialogVisibleUserEdit = true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = {
            username: row.username,
          };
          deleteUser(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleBinding(index, row) {
      console.log(index, row);
      const username = row.username;
      router.push({
        path: "/bind-monitory-point",
        query: { username: username },
      });
    },
    handleSwitch(index, row) {
      if (row.username === "superAdmin") {
        return;
      }
      console.log(index, row);
      const params = {
        code: row.enable ? 1 : 0,
        username: row.username,
      };
      switchUserStatus(params);
    },
    handleClear() {
      this.usernameInput = "";
    },
    handleSearch() {
      console.log(this.usernameInput);
      this.getList();
    },
    handleAddUser() {
      this.dialogVisibleUserAdd = true;
    },
    closeAddUser() {
      this.dialogVisibleUserAdd = false;
    },
    closeEditUser() {
      this.dialogVisibleUserEdit = false;
    },
    closeSetRole() {
      this.dialogVisibleRoleSet = false;
    },
    getList() {
      const param = {
        usernameInput: this.usernameInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        data.list.forEach((item) => {
          item.enable = item.enable === 1;
        });
        this.tableData = data.list;
      });
    },
  },
};
</script>
<style lang="css"></style>
